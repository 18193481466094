/* Copyright 2022- Martin Kufner */
import {cT} from '../content-tag'
import '../dom-extensions'
import '../js-extensions'

cT.register();

export class QbElement extends HTMLElement {
    static event_update = false

    static get nodeName() {
        return customElements.nodeName(this);
    }

    static get register() {
        return customElements.register(this);
        // const nodeName = this.nodeName.toLowerCase();
        // const registry = self.customElements;
        // registry.get(nodeName) || registry.define(nodeName, this);
        // // customElements.register(this);
        // return this;
    }

    #connectedCallbacks = [];
    #connectedPromise;
    #connectedResolve;
    #disconnectedCallbacks = [];
    #disconnectedPromise;
    #disconnectedResolve;
    #shadowRoot;
    #contentRoot;
    #trackSelection;
    #beforeInitUpdates = []

    constructor() {
        super();
        if('shadow' in this.constructor) this.#setShadow;
        if('template' in this.constructor) this.#content = this.constructor.template;
        this.#contentRoot = this;
        Promise.resolve().then(()=>{
            this.init;
            const beforeInitUpdates = this.#beforeInitUpdates;
            this.#beforeInitUpdates = null;
            for(const data of beforeInitUpdates) this.update(...data);
        });
    }

    get contentRoot() {
        return this.#shadowRoot || this.#contentRoot;
    }

    get #setShadow() {
        let shadowOptions = this.constructor.shadow;
        if(typeof shadowOptions !== "object") shadowOptions = {mode: shadowOptions};
        this.#shadowRoot = this.attachShadow(shadowOptions);
        if(shadowOptions.sheets) this.#shadowRoot.adoptStyleSheets(...shadowOptions.sheets);
    }

    set #content(value) {
        if(!value) return;
        for(const n of this.contentRoot.childNodes) (n instanceof HTMLLinkElement) || n.remove();
        if(typeof value === "string") {
            const template = document.createElement('div');
            template.innerHTML = value;
            // value = template;
            this.contentRoot.append(...template.children);
        }
        else if(value.nodeName === "TEMPLATE") throw "template does not work properly, use text (innerHTML) instead";
        else if(value.nodeName instanceof HTMLElement) this.contentRoot.append(value);
        cT.parse(this.contentRoot, this);
    }

    set content(value) {
        this.#content = value;
    }

    get connectedPromise() {
        if(!this.#connectedPromise) this.#connectedPromise = Promise.withResolvers();
        return this.#connectedPromise;
    }

    connected(callback, persist) {
        if(persist) this.#connectedCallbacks.push(callback);
        return this.connectedPromise.promise.then(callback)
    }

    get disconnectedPromise() {
        if(!this.#disconnectedPromise) this.#disconnectedPromise = Promise.withResolvers();
        return this.#disconnectedPromise;
    }

    disconnected(callback, persist) {
        if(persist) this.#disconnectedCallbacks.push(callback);
        return this.disconnectedPromise.promise.then(callback);
    }

    connectedCallback() {
        this.connectedPromise.resolve(this);
        if(this.constructor.event_update) this.listen('update', e => this.update(e.detail));
    }

    disconnectedCallback() {
        this.disconnectedPromise.resolve(this);
        this.#connectedPromise = this.#disconnectedPromise = undefined;
        this.#connectedCallbacks.forEach(callback => this.#connectedPromise.then(callback));
        this.#disconnectedCallbacks.forEach(callback => this.#disconnectedPromise.then(callback));
    }

    handleEvent(evt) {

        let fn;
        const type = evt.type.replace(/-([a-z])/g, (m,m1)=>m1.toUpperCase());
        if(evt.currentTarget.handleEventTargets) {
            const handleEventTarget = evt.currentTarget.handleEventTargets[type]
            fn = this[`handleEvent_${handleEventTarget}_${type}`] || this[`handleEvent_${handleEventTarget}`];
        }
        if(!fn) fn = this[`handleEvent_${type}`];
        if(fn) return fn.call(this, evt);
        console.info("handleEventTarget not found for", evt.currentTarget, evt.type, evt)
    }

    #updateProperty(property, value) {
        if(value === undefined) return;
        const m = this.__lookupSetter__(property);
        if(m) m.call(this, value);
        else console.trace( `${this.constructor.name} MISSING set ${property}(value) {}`, value, this);
        return this;
    }

    update(data, value) {
        // if(this.#beforeInitUpdates) return this.#beforeInitUpdates.push([data, value]);
        if(typeof data !== 'object') data = [[data, value]];
        if(("removed" in data) && data.removed === true) return this.remove();
        // this.initialized.then(() => {
        const updateOnConnected = [];
        if(!(data instanceof Array)) data = Object.entries(data);
        if('updateSequence' in this.constructor) {
            const u = this.constructor.updateSequence;
            data = data.sort(([l], [h]) => (u.indexOf(l) + 1 || Infinity) - (u.indexOf(h) + 1 || Infinity));
        }
        for(const [k,v] of data) {
            try {
                let method = value === undefined ? k : `${value}_${k}`;
                if(this.constructor.updateWhenConnected?.include(k)) updateOnConnected.push([method, v]);
                else this.#updateProperty(method, v);
            }
            catch(e) {
                console.error(this, `${this.constructor.name}.${k}=`, v, e);
            }
        }
        if(updateOnConnected.length) this.connected(() => {
            for(const args of updateOnConnected) {
                try {
                    this.#updateProperty(...args);
                }
                catch(e) {
                    console.error(e);
                }
            }
        });
        // });
        return this;
    }


    attributeChangedCallback(name, oldValue, newValue) {
        if(oldValue === newValue) return;
        // console.log(name, oldValue,"->", newValue);
        if(newValue === "true" || newValue === name) newValue = true;
        else if(newValue === "false") newValue = false;
        this[`$${name}`]?.call(this, newValue, oldValue);
    }

    dispatch(type, detail = {}) {
        this.dispatchEvent(type instanceof Event ? type : new CustomEvent(type, {bubbles: true, detail}));
    }

    listen(type, cb) {
        if(typeof cb === "string") cb = this[cb].bind(this);
        if(cb && typeof cb !== "function") return console.trace(`listen(${type}, ${cb}) cb not a function`);
        this.addEventListener(type, cb || this)
    }

    events(...events) { return cT(this, {events}); }

    observe(fn, options) {
        options = Object.assign({childList: true}, options);
        if(this.childChangeObserver) this.childChangeObserver.disconnect();
        this.childChangeObserver = new MutationObserver((m, o) => fn(m, o)).observe(this, options);
    }

    get computedStyle() {
        return window.getComputedStyle(this);
    }

    computedProperty(prop) {
        return this.computedStyle.getPropertyValue(prop.replace(/^(--)?/, "--"));
    }

    get order() { return parseInt(this.computedStyle.order); }

    set order(order) {
        if(order instanceof Date) {
            const x = order;
            const delta = Math.floor(order.seconds - Date.now() / 10);
            order = Math.floor(Math.sqrt(Math.abs(delta)) * 1.45) * Math.sign(delta) * Math.sign(parseInt(order.dir) || 1);
        }
        this.style.order = parseInt(order);
    }

    cache(name, value) {
        Object.defineProperty(this, name, {value});
        return value;
    }


}

QbElement.register

export class QbInputElement extends QbElement {
    static formAssociated = true;

    constructor() {
        super();
        this.internals = this.attachInternals();
    }

    get form() {
        return this.internals.form;
    }

    get formData() {
        const formData = new FormData(),
            name = this.name;
        if(!this.__lookupGetter__('hash')) formData.set(name || 'value', this.value);
        else Object.entries(this.hash).forEach(([k, v]) => formData.set(name ? `${name}[${k}]` : k, v));
        return formData;
    }

    get name() { return this.getAttribute("name") }

    get readonly() { return this.hasAttribute("readonly") }
}

// get siblings() {
//     return Array.from(this.parentElement?.children || [])
// }
//
// get nthChild() {
//     return this.siblings.indexOf(this) + 1
// }
//
// get siblingsType() {
//     return this.siblings.filter(link => link.nodeName === this.nodeName)
// }
//
// get nthType() {
//     return this.siblingsType.indexOf(this) + 1
// }
//
// get siblingsKind() {
//     const m = this.nodeName.match(/^(Qb-[^\-]+)-[^\-]+$/);
//     const re = m ? new RegExp(`^${m[1]}(-.+)?$`) : new RegExp(`^${this.nodeName}(-.+)?$`);
//     return this.siblings.filter(link => re.test(link.nodeName));
// }
//
// get nthKind() {
//     return this.siblingsKind.indexOf(this) + 1
// }
//
// previousElementSiblingMatch(match) {
//     let s = this.previousElementSibling
//     for (; s && !s.matches(match); s = s.previousElementSibling) {
//     }
//     return s;
// }
//
// previousElementsSiblingTill(match) {
//     let s = this.previousElementSibling;
//     const rv = [];
//     for (; s && !s.matches(match); s = s.previousElementSibling) {
//         rv.push(s)
//     }
//     return rv;
// }
//
// nextElementSiblingMatch(match) {
//     let s = this.nextElementSibling;
//     for (; s && !s.matches(match); s = s.nextElementSibling) {
//     }
//     return s;
// }
//
// nextElementsSiblingTill(match) {
//     let s = this.nextElementSibling;
//     const rv = [];
//     for (; s && !s.matches(match); s = s.nextElementSibling) {
//         rv.push(s)
//     }
//     return rv;
// }