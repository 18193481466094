import "./qb-participants"

window.addEventListener("click", e => {
    const details = e.target.closest("details");
    for(const n of document.getElementsByTagName("details")) {
        if(n !== details || n.classList.contains('no-autoclose')) n.open = false;
    }
});

import "@qb-assets-js/qb-logger"

import MarkdownIt from "@node/markdown-it"
self.MarkdownIt = MarkdownIt;

const sfh = () => {
    document.documentElement.style.setProperty("--window-width", `${window.innerWidth}px`);
    document.documentElement.style.setProperty("--window-height", `${window.innerHeight}px`);
}
window.addEventListener("resize", sfh);
sfh();

// document.addEventListener("selectionchange", e=>console.log(e));