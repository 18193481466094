// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')
window.loadDebug = true;

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import "/application";
self.colorScheme = {
    get() {
        return localStorage.getItem("color-scheme") || "light";
    },
    set(scheme) {
        if(!scheme) scheme = colorScheme.get();
        document.documentElement.dataset.colorScheme = scheme === "dark" ? "dark" : "light";
        localStorage.setItem("color-scheme", scheme);
    },
    toggle() {
        const scheme = localStorage.getItem("color-scheme") === "dark" ? "light" : "dark";
        colorScheme.set(scheme);
    }
}
colorScheme.set();

self.toggleUnfinished = () => {
    if(document.documentElement.classList.toggle('show-unfinished')) {
        document.querySelectorAll("qb-show").forEach(n=>n.markUnfinished);
        for(const n of document.body.getElementsByClassName("unfinished"))
            n.classList.add('show-unfinished');
    }
    else {
        for(const n of document.body.getElementsByClassName("show-unfinished"))
            n.classList.remove('show-unfinished');
    }
}

self.msFormatted = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

    return `${hours}:${minutes}:${seconds}.${milliseconds}`;
}
