class QbIconBase extends HTMLElement {
    constructor() {
        super();
        const template = document.createElement("template");
        const varName = "--" + this.nodeName.toLowerCase().replace(/^i-/, "icon-");
        template.innerHTML = `<style>span:before { content: var(${varName}); }</style><span></span>`;
        this.attachShadow({mode: "open"}).append(template.content);
    }

    connectedCallback() {
        this.classList.add("qb-icon");
        if(this.hasAttribute("aria-label")) return;
        this.setAttribute("aria-label", this.nodeName.toLowerCase().replace(/^i-/, ""));
    }

    static register(nodeName) {
        nodeName = "i-" + nodeName.toLowerCase().replace(/^-*i(?:con)?-/, "");
        if(customElements.get(nodeName)) return nodeName;
        customElements.define(nodeName, class extends this {});
        return nodeName;
    }
}

self.QbIcon = function (nodeName, ...args) {
    nodeName = QbIconBase.register(nodeName);
    return cT(nodeName, ...args);
}
self.QbIcon.register = () => {
    document.customPropertyNames.forEach(p => /^--icon-/.test(p) && QbIconBase.register(p));
}
